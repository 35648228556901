export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは9月より%d点上がりました。その調子です。<br>
      今年も残り2か月です。良い生活習慣はこのまま継続していきましょう！<br>
      寒くなってきたり、空気が乾燥すると、風邪をひきやすくなります。<br>
      睡眠・食事・運動など免疫力を上げるために今の自分に何ができるか考えてみましょう。<br>
      コラム▶「<a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">免疫力アップの鍵はコレだ！！（コラム）</a>」`,
      down: `生活習慣スコアは9月より%d点下がりました。今年も残り2か月です。<br>
      年末にかけて忙しくなる時期こそ、健康管理を気にかけて体調を整えましょう。<br>
      寒くなってきたり、空気が乾燥すると、風邪をひきやすくなります。<br>
      睡眠・食事・運動など免疫力を上げるために、何かできそうなことはありますか？<br>
      コラム▶「<a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">免疫力アップの鍵はコレだ！！（コラム）</a>」`,
      equal100: `生活習慣スコアは9月に引き続き%満点%です。大変素晴らしいです。<br>
      今年も残り2か月です。良い生活習慣はこのまま継続していきましょう！<br>
      寒くなってきたり、空気が乾燥すると、風邪をひきやすくなります。<br>
      睡眠・食事・運動など免疫力を上げるために今の自分に何ができるか考えてみましょう。<br>
      コラム▶「<a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">免疫力アップの鍵はコレだ！！（コラム）</a>」`,
      equal: `生活習慣スコアは9月と同じ点数です。今年も残り2か月です。<br>
      年末にかけて忙しくなる時期こそ、健康管理を気にかけて体調を整えましょう。<br>
      寒くなってきたり、空気が乾燥すると、風邪をひきやすくなります。<br>
      睡眠・食事・運動など免疫力を上げるために、何かできそうなことはありますか？<br>
      コラム▶「<a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">免疫力アップの鍵はコレだ！！（コラム）</a>」`,
    },
    steps: {
      up: `歩数スコアは9月より%d点上がりました。<br>
      膝裏や足首などをストレッチして、ケガや疲労予防に繋げましょう。ストレッチ中に呼吸をするのも忘れずに。`,
      down: `歩数スコアは9月より%d点下がりました。<br>
      膝裏や足首などをストレッチして、ケガや疲労予防に繋げましょう。ストレッチ中に呼吸をするのも忘れずに。`,
      equal: `歩数スコアは9月と同じです。<br>
      膝裏や足首などをストレッチして、ケガや疲労予防に繋げましょう。ストレッチ中に呼吸をするのも忘れずに。`,
    },
    exercise: {
      up: `運動のスコアは9月より%d点上がりました。<br>
      スポーツの秋を満喫していますか？運動には、体力や筋力がつくこと以外に、気分転換やストレス解消など「心の健康効果」もあります♪`,
      down: `運動のスコアは9月より%d点下がりました。<br>
      運動には、ストレス解消など「心の健康効果」もあります♪特にウォーキングなどの有酸素運動がストレス緩和に適していると言われていますよ。`,
      equal: `運動のスコアは9月と同じです。<br>
      運動には、ストレス解消など「心の健康効果」もあります♪特にウォーキングなどの有酸素運動がストレス緩和に適していると言われていますよ。`,
    },
    meal: {
      up: `食事のスコアは9月より%d点上がりました。<br>
      <b>ビタミンＤ</b>には、<b>体内の免疫力をあげる働き</b>があります。魚類（特に鮭やサンマ）やキノコ類に多く含まれています。詳しい解説動画は<a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">こちら</a>。`,
      down: `食事のスコアは9月より%d点下がりました。<br>
      <b>ビタミンＤ</b>には、<b>体内の免疫力をあげる働き</b>があります。魚類（特に鮭やサンマ）やキノコ類に多く含まれています。詳しい解説動画は<a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">こちら</a>。`,
      equal: `食事のスコアは9月と同じです。<br>
      <b>ビタミンＤ</b>には、<b>体内の免疫力をあげる働き</b>があります。魚類（特に鮭やサンマ）やキノコ類に多く含まれています。詳しい解説動画は<a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">こちら</a>。`,
    },
    drinking: {
      up: `飲酒のスコアは9月より%d点上がりました。<br>
      その調子です。長く楽しむためにも、引き続きお酒と上手くお付き合いしていきましょう。`,
      down: `飲酒のスコアは9月より%d点下がりました。<br>
      どんな時にお酒を飲み過ぎてしまいますか？自分ログの今日のメモも上手く活用しましょう。`,
      equal: `飲酒のスコアは9月と同じ点数です。<br>
      「酒は百薬の長」と言われるように、お酒は適量を上手に飲めば疲労回復やストレス解消に役立ちます。`,
    },
    sleep: {
      up: `睡眠のスコアは9月より%d点上がりました。<br>
      快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。`,
      down: `睡眠のスコアは9月より%d点下がりました。<br>
      快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。`,
      equal: `睡眠のスコアは9月と同じです。<br>
      快適な睡眠をとるために、寝室の<b>湿度を50％程度</b>に保ちましょう。乾燥による喉や肌への負担も少なくなります。`,
    },
    stress: {
      up: `ストレスのスコアは9月より%d点上がりました。<br>
      笑いは免疫機能にも関係します。1日どのくらい笑っていますか？日常生活で笑いを増やす方法を<a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">こちら</a>の動画で紹介。`,
      down: `ストレスのスコアは9月より%d点下がりました。<br>
      笑いは免疫機能にも関係します。1日どのくらい笑っていますか？日常生活で笑いを増やす方法を<a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">こちら</a>の動画で紹介。`,
      equal: `ストレスのスコアは9月と同じです。<br>
      笑いは免疫機能にも関係します。1日どのくらい笑っていますか？日常生活で笑いを増やす方法を<a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">こちら</a>の動画で紹介。`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since September. Keep it up!<br>
      You've only got two months left in the year! Keep up the good lifestyle habits! <br>
      It's easy to catch a cold when the weather gets chilly and dry. <br>
      Think about what you can do right now to boost your immunity, like working on your sleep, eating, and exercise habits. <br>
      Column ▶ <a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">Here's the Key to Boosting Your Immunity!! (Column)</a>`,
      down: `Your lifestyle score has gone down %d pts since September. You've only got two months left in the year!
      Make sure to watch your health and work on staying healthy, especially as we get into the end of the year and things get busier. 
      It's easy to catch a cold when the weather gets chilly and dry. 
      What can you do to boost your immunity? Perhaps work on your sleep, eating, or exercise habits? 
      Column ▶ <a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">Here's the Key to Boosting Your Immunity!! (Column)</a>`,
      equal100: `Your lifestyle score is the %maximum% again, the same as September. You're doing fantastic!<br>
      You've only got two months left in the year! Keep up the good lifestyle habits! <br>
      It's easy to catch a cold when the weather gets chilly and dry. <br>
      Think about what you can do right now to boost your immunity, like working on your sleep, eating, and exercise habits. <br>
      Column ▶ <a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">Here's the Key to Boosting Your Immunity!! (Column)</a>`,
      equal: `Your lifestyle score is the same as September. You've only got two months left in the year!<br>
      Make sure to watch your health and work on staying healthy, especially as we get into the end of the year and things get busier. <br>
      It's easy to catch a cold when the weather gets chilly and dry. <br>
      What can you do to boost your immunity? Perhaps work on your sleep, eating, or exercise habits? <br>
      Column ▶ <a href="https://go.andwell.jp/library/ade9ba62-5e3b-11ec-9728-06d158a16ffc">Here's the Key to Boosting Your Immunity!! (Column)</a>`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since September.<br>
      Stretch areas such as the backs of your knees and your ankles to prevent injuries and fatigue. Remember to breathe while stretching.
      `,
      down: `Your steps score has gone down %d pts since September. <br>
      Stretch areas such as the backs of your knees and your ankles to prevent injuries and fatigue. Remember to breathe while stretching.
      `,
      equal: `Your steps score is the same as September. <br>
      Stretch areas such as the backs of your knees and your ankles to prevent injuries and fatigue. Remember to breathe while stretching.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since September.<br>
      Are you enjoying this autumn of sports to the fullest? In addition to making your body and muscles stronger, exercising also has an effect on your mental health. It can give you a change of pace, relieve stress, and more.
      `,
      down: `Your exercise score has gone down %d pts since September.<br>
      Exercise has an effect on your mental health, too—relieving stress is one of them! Aerobic exercise, such as walking, is said to be especially great for relieving stress.
      `,
      equal: `Your exercise score is the same as September.<br>
      Exercise has an effect on your mental health, too—relieving stress is one of them! Aerobic exercise, such as walking, is said to be especially great for relieving stress.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since September.<br>
      <b>Vitamin D boosts your immune system</b>. High levels of it are found in fish (especially salmon and saury) and mushrooms. Click <a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">here</a> for a detailed explanation video.`,
      down: `Your food score has gone down %d pts since September.<br>
      <b>Vitamin D boosts your immune system</b>. High levels of it are found in fish (especially salmon and saury) and mushrooms. Click <a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">here</a> for a detailed explanation video.`,
      equal: `Your food score is the same as September.<br>
      <b>Vitamin D boosts your immune system</b>. High levels of it are found in fish (especially salmon and saury) and mushrooms. Click <a href="https://go.andwell.jp/library/2fe049ac-5c80-11ec-9728-06d158a16ffc">here</a> for a detailed explanation video.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since September.<br>
      Keep it up! Keep enjoying alcohol in moderation so you'll be able to enjoy it for a long time, among other reasons.`,
      down: `Your alcohol score has gone down %d pts since September.<br>
      When do you end up drinking too much? Make good use of the Today's Notes section in your Self-Log to keep track of that.`,
      equal: `Your alcohol score is the same as September.<br>
      As the saying goes, "Good wine makes good cheer." Drinking appropriate amounts of alcohol can help you recover from fatigue and relieve your stress.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since September.<br>
      Keep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.`,
      down: `Your sleep score has gone down %d pts since September.<br>
      Keep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.`,
      equal: `Your sleep score is the same as September.<br>
      Keep the humidity in your bedroom at <b>around 50%</b> for a good night's sleep. This also makes the dryness less harsh on your throat and skin.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since September.<br>
      Laughter also plays a part in immune function. How often do you laugh each day? Click <a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">here</a> for a video that'll show you how to laugh more in your everyday life.`,
      down: `Your stress score has gone down %d pts since September.<br>
      Laughter also plays a part in immune function. How often do you laugh each day? Click <a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">here</a> for a video that'll show you how to laugh more in your everyday life.`,
      equal: `Your stress score is the same as September.<br>
      Laughter also plays a part in immune function. How often do you laugh each day? Click <a href="https://go.andwell.jp/library/37cfbf04-62bf-11ec-9ce8-0af363eb5062">here</a> for a video that'll show you how to laugh more in your everyday life.`,
    },
  },
}
